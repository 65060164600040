import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import uuid4 from "uuid4";
import Button from "react-bootstrap/Button";
import { SafeHtmlParser } from "./safe-html-parser";

const ThankYouHero = ({
  heading,
  backgroundImage,
  content,
  subheading,
  image,
  button,
}) => {
  const overlayColor = "rgba(0, 0, 0, 0)";
  const overlayStyle = {
    background: `linear-gradient(${overlayColor} 0%, ${overlayColor} 100%)`,
    backgroundBlendMode: "multiply",
    zIndex: 1,
  };
  const backgroundStyle = {
    marginTop: "-107px",
    paddingTop: "134px",
    paddingBottom: "56px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: "700px",
    zIndex: 2,
  };

  return (
    <div
      style={{ minHeight: "700px" }}
      className="hero-home h-100 bg-blue-light"
    >
      <BgImage
        className="w-100 h-100 d-flex align-items-start justify-content-center banner-landing"
        image={getImage(backgroundImage)}
        style={backgroundStyle}
        key={uuid4()}
      >
        <div
          className="position-absolute start-0 top-0 h-100 w-100"
          style={overlayStyle}
        ></div>
        <Container className="py-5 py-md-6 px-xl-4 h-100" style={{ zIndex: 4 }}>
          <Row className="justify-content-center align-items-start h-100">
            <Col xs={10} sm={6} xl={3}>
              <div className="mb-4" key={uuid4()}>
                <GatsbyImage image={image} style={{ maxHeight: "640px" }} />
              </div>
            </Col>
            <Col xs={10} sm={7} xl={5} className="text-start">
              <div className="w-100 px-xl-5 position-relative text-center text-xl-start">
                {subheading && (
                  <h4 className="text-white mb-0">{subheading}</h4>
                )}
                <h1 className="my-4 text-white display-6">{heading}</h1>
                {content && (
                  <div className="content-white mt-3">
                    <SafeHtmlParser htmlContent={content} />
                  </div>
                )}
                {button && button.url && (
                  <Button
                    className="px-4 py-3 mt-4 me-1 mb-0 mx-auto mx-xl-0 w-fit daxPro-regular rounded-0 text-uppercase border-1"
                    as={Link}
                    to={button?.url}
                    variant="transparent"
                    target={button?.target ?? "_blank"}
                  >
                    {button?.title ?? "Contact Us"}
                    <StaticImage
                      src="../images/arrow-up-outline.svg"
                      alt="google"
                      formats={["auto", "webp"]}
                      className="ms-2"
                      width={18}
                      height={12}
                    />
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </BgImage>
    </div>
  );
};

export default ThankYouHero;
