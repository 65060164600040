import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Link } from "gatsby";
import React from "react";
import { Button } from "react-bootstrap";
import Layout from "../components/landing-layout";
import { Helmet } from "react-helmet";
import HeroSection from "../components/thank-you-hero";
import TwoColumnSection from "../components/two-column-section";
import { Container, Row } from "react-bootstrap";
import BlogCard from "../components/blog-card";
import uuid4 from "uuid4";

const ThankYouPageTemplate = ({ data: { site, pageData } }) => {
	const {
		thankYouPageFieldsGroup: { thankYouPageFieldsGroup },
		title,
	} = pageData;

	return (
		<>
			<GatsbySeo title={title} language="en" noindex nofollow />
			<Layout isThankYouPage={true}>
				{thankYouPageFieldsGroup &&
					thankYouPageFieldsGroup.length > 0 &&
					thankYouPageFieldsGroup.map((sectionInfo) => (
						<>
							{sectionInfo.fieldGroupName ===
								"ThankYouPage_Thankyoupagefieldsgroup_ThankYouPageFieldsGroup_BannerSection" && (
								<HeroSection
									heading={sectionInfo?.heading}
									subheading={sectionInfo?.subheading}
									content={sectionInfo?.content}
									backgroundImage={
										sectionInfo?.backgroundImage?.localFile?.childImageSharp
											.gatsbyImageData
									}
									image={
										sectionInfo?.image?.localFile?.childImageSharp
											?.gatsbyImageData
									}
									button={sectionInfo?.link}
									key={uuid4()}
								/>
							)}

							{sectionInfo.fieldGroupName ===
								"ThankYouPage_Thankyoupagefieldsgroup_ThankYouPageFieldsGroup_SectionLayout1" && (
								<TwoColumnSection
									heading={sectionInfo?.heading}
									content={sectionInfo?.description}
									button={sectionInfo?.link}
									image={sectionInfo?.image}
									key={uuid4()}
									halfWidth={true}
									isTheColumnFullWidth={sectionInfo?.isTheColumnFullWidth}
									isThankYouPage={true}
								/>
							)}
							{sectionInfo.fieldGroupName ===
								"ThankYouPage_Thankyoupagefieldsgroup_ThankYouPageFieldsGroup_SectionLayout2" && (
								<section className="mb-5 mb-md-6 my-xl-6">
									{sectionInfo?.items && sectionInfo?.items.length > 0 && (
										<Container>
											<Row className="g-40 mt-4">
												{sectionInfo?.items.map((item) => (
													<BlogCard
														title={item.title}
														image={
															item.projectFields?.projectFeatureImage?.localFile
																.childImageSharp?.gatsbyImageData
														}
														uri={item.uri}
														excerpt={null}
														imageAlt={
															item.projectFields?.projectFeatureImage?.altText
														}
														key={uuid4()}
														textSize="fs-6"
														showReadMore={false}
													/>
												))}
											</Row>
											{sectionInfo?.link && (
												<Button
													className="px-4 py-3 mt-4 mb-0 w-fit daxPro-semibold mx-auto"
													as={Link}
													to={sectionInfo?.link?.url}
													variant="secondary"
													target={sectionInfo?.link?.target ?? "_blank"}
												>
													{sectionInfo?.link?.title ?? "Contact Us"}
												</Button>
											)}
										</Container>
									)}
								</section>
							)}
						</>
					))}
			</Layout>
		</>
	);
};

export default ThankYouPageTemplate;

export const pageQuery = graphql`
	query ($id: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		pageData: wpThankYouPage(slug: { eq: $id }) {
			title
			slug
			thankYouPageFieldsGroup {
				thankYouPageFieldsGroup {
					... on WpThankYouPage_Thankyoupagefieldsgroup_ThankYouPageFieldsGroup_BannerSection {
						content
						fieldGroupName
						heading
						subheading
						backgroundImage {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						link {
							target
							title
							url
						}
					}
					... on WpThankYouPage_Thankyoupagefieldsgroup_ThankYouPageFieldsGroup_SectionLayout1 {
						fieldGroupName
						heading
						description
						link {
							target
							title
							url
						}
						isTheColumnFullWidth
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpThankYouPage_Thankyoupagefieldsgroup_ThankYouPageFieldsGroup_SectionLayout2 {
						fieldGroupName
						items {
							... on WpProject {
								id
								uri
								projectFields {
									projectFeatureImage {
										altText
										localFile {
											publicURL
											childImageSharp {
												original {
													height
													width
												}
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
								title
							}
						}
						link {
							target
							title
							url
						}
					}
				}
			}
		}
	}
`;